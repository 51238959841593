import { Box, Grid, Typography } from '@mui/material';
import { BarChart, Phone, CheckCircle, Message, TrendingUp } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import VuiBox from 'components/atoms/VuiBox';
import { UsersContext } from 'store/api/usersApi';
import { useLoading } from 'hooks/useLoading';

const Analytics = () => {
  const { getAnalytics } = useContext(UsersContext);
  const [analytics, setAnalytics] = useState();
  const { showLoading, loading, closeLoading } = useLoading();
  useEffect(() => {
    showLoading('fetching analytics');
    getAnalytics().then((res) => {
      console.log(res);
      setAnalytics(res);
      closeLoading();
    });
  }, []);
  // const analytics = {
  //   data: {
  //     total: {
  //       ai_booked: 33,
  //       engagements: 87,
  //       reached_outs: 1097
  //     },
  //     weeks: [
  //       {
  //         cohort_analytics: {
  //           ai_booked: 8,
  //           engagements: 16,
  //           reached_outs: 95
  //         },
  //         end: 1724025600,
  //         start: 1723420800
  //       },
  //       {
  //         cohort_analytics: {
  //           ai_booked: 5,
  //           engagements: 16,
  //           reached_outs: 28
  //         },
  //         end: 1724630400,
  //         start: 1724025600
  //       }
  //       //... Other weeks data
  //     ]
  //   },
  //   message: 'User analytics retrieved',
  //   success: true
  // };

  const totalAnalytics = analytics?.data?.total;
  const weeklyData = analytics?.data?.weeks?.map((week) => ({
    start: new Date(week.start * 1000).toLocaleDateString(),
    end: new Date(week.end * 1000).toLocaleDateString(),
    ...week.cohort_analytics
  }));

  const chartOptions = {
    chart: {
      id: 'weekly-analytics',
      type: 'line'
    },
    xaxis: {
      categories: weeklyData?.map((data) => `${data.start} - ${data.end}`)
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Weekly Cohort Analytics',
      align: 'left'
    }
  };

  const series = [
    {
      name: 'AI Booked',
      data: weeklyData?.map((data) => data.ai_booked)
    },
    {
      name: 'Engagements',
      data: weeklyData?.map((data) => data.engagements)
    },
    {
      name: 'Reached Outs',
      data: weeklyData?.map((data) => data.reached_outs)
    }
  ];

  return (
    <VuiBox sx={{ p: { xs: 0, lg: 5 } }}>
      {/* Total Analytics Section */}

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Box display="flex" alignItems="center">
              <BarChart color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                Engagements: {totalAnalytics?.engagements}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box display="flex" alignItems="center">
              <TrendingUp color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                Reached Outs: {totalAnalytics?.reached_outs}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box display="flex" alignItems="center">
              <CheckCircle color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                AI Booked: {totalAnalytics?.ai_booked}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Weekly Analytics Chart */}
      <div className="weekly-analytics">
        <Chart options={chartOptions} series={series} type="line" height="350" />
      </div>
    </VuiBox>
  );
};

export default Analytics;
